<template>
  <div>
    <div class="flex justify-end mt-3 mb-6">
        <GridViewListView />
    </div>
    <Card>
      <template slot="outer">
        <PageTopOuter
            icon="group"
            label="Materials Group"
         />
      </template>
      <CardBody class="p-3">
        <Table :items="groupMaterials">
          <template slot="row" slot-scope="{ item }" md-selectable="single">
            <md-table-cell md-label="GROUP ID">{{ item.groupId }}</md-table-cell>
            <md-table-cell md-label="FILE NAME" class="text-victoria">{{ item.fileName }}</md-table-cell>
            <md-table-cell md-label="CREATED BY">{{ item.createdBy }}</md-table-cell>
            <md-table-cell md-label="CREATED AT">{{ item.createdAt }}</md-table-cell>
            <md-table-cell md-label="DOWNLOAD ACCESS">
                <md-icon class="text-green">{{ item.downloadAccess ? 'check': ''}}</md-icon>
            </md-table-cell>
            <md-table-cell md-label="NO OF FILE">{{ item.NoOfFile }}</md-table-cell>
            <md-table-cell md-label="MATERIAL RELATED WITH">{{ item.materialRelatedWith }}</md-table-cell>
          </template>
        </Table>
      </CardBody>
    </Card>
    <Dialog>
      <CreateNewGroup />
    </Dialog>
  </div>
</template>

<script>
import { Card, CardBody, Table, Dialog } from "@/components";
import GridViewListView from "@/components/atom/GridViewListView";
import PageTopOuter from "@/components/molecule/PageTopOuter";
import CreateNewGroup from "@/components/molecule/material/CreateNewGroup";
import data from "@/data/material/data";
import { mapMutations } from 'vuex'
export default {
  data() {
    return {
      groupMaterials: data.groupMaterials
    };
  },
  components: {
    Card,
    Table,
    Dialog,
    CardBody,
    PageTopOuter,
    CreateNewGroup,
    GridViewListView
  },
  methods: mapMutations({
      dialog: "setShowDialog",
    }),
};
</script>
