<template>
  <div class="p-6">
    <DialogTitle label="Create New Material Group" align="center" />
    <form @submit.prevent="onSubmit">
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-size-50 mb-3">
          <InputFieldComponent
            italic
            label="Group Name"
            v-model.trim="$v.form.groupName.$model"
            :message="!$v.form.groupName.required && $v.form.groupName.$dirty ? 'Field is required' : null"
          />
        </div>
        <div class="md-layout-item md-size-25 mb-3">
          <SelectComponent
            label="Category"
            placeholder="SPEAKING"
            italic
            class="w-full"
            v-model.trim="$v.form.category.$model"
            :message="!$v.form.category.required && $v.form.category.$dirty ? 'Field is required' : null"
          />
        </div>
        <div class="md-layout-item md-size-25 mb-3">
          <SelectComponent
            label="Section"
            placeholder="RO"
            italic
            class="w-full"
            v-model.trim="$v.form.section.$model"
            :message="!$v.form.section.required && $v.form.section.$dirty ? 'Field is required' : null"
          />
        </div>
        <div class="md-layout-item md-size-100">
          <AccordionComponent
            bg-head="bg-victoria"
            bg-body="bg-snuff"
          />
        </div>
        <div class="md-layout-item md-size-85 mb-3 mt-4">
          <TextAreaComponent
            label="Note"
            v-model.trim="$v.form.note.$model"
            :message="!$v.form.note.required && $v.form.note.$dirty ? 'Field is required' : null"
          />
        </div>
        <div class="md-layout-item md-size-50 mb-3">
          <div class="flex align-center">
            <md-checkbox
              v-model="form.downloadable"
              true-value="true"
              false-value="false"
            ></md-checkbox>
            <span>Access to download</span>
          </div>
          <p class="text-xs text-danger" v-if="!$v.form.downloadable.required && $v.form.downloadable.$dirty">Field is required</p>
        </div>

        <div class="flex justify-end align-center md-layout-item md-size-50 mb-3">
          <md-button class="md-default">Save As Draft</md-button>
          <md-button class="border border-solid border-gray-500 rounded"
            >Schedule</md-button
          >
          <md-button type="submit" class="bg-victoria text-white rounded">Create</md-button>
        </div>
      </div>
      </form>
  </div>
</template>

<script>
import {InputFieldComponent, SelectComponent, DialogTitle, TextAreaComponent} from "@/components";
import AccordionComponent from "@/components/molecule/AccordionComponent";
import { required } from "vuelidate/lib/validators";
import { mapMutations } from "vuex";
export default {
  components: {
    DialogTitle,
    SelectComponent,
    TextAreaComponent,
    AccordionComponent,
    InputFieldComponent,
  },
  data() {
    return {
      value: true,
      form: {
        groupName: "",
        category: "",
        section: "",
        note: "",
        downloadable: ""
      }
    };
  },
   methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar"
    }),
    onSubmit() {
      this.$v.$touch();

      if(this.$v.$invalid) {
        return;
      }
      this.setShowSnackbar('Material group saved successfully !');
      setTimeout(() => {
       this.dialog(false);
      }, 1000);
    },
  },
  validations: {
      form: {
        groupName: {required},
        category: {required},
        section: {required},
        note: {required},
        downloadable: {required},
      }
    }
};
</script>

<style></style>
